<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="copyright pull-right">
        <a
          href="#"
          @click="triggerReleaseNotesModal()"
        > v{{ version }} </a> -
        <version-link />
        <a
          href="#"
          @click="window.triggerModal()"
        >
          change cookie preferences
        </a>
        -
        <span>
          <a
            href="https://www.toolsquare.io/legal/platform-privacy-policy"
            rel="noopener"
            target="_blank"
          >Privacy Policy</a>
          -
        </span>
        &copy;
        <span>{{ new Date().getFullYear() }}
          <a
            href="https://www.toolsquare.io"
            rel="noopener"
            target="_blank"
          >Toolsquare.io</a></span>
      </div>
    </div>
  </footer>
</template>
<script setup>
import VersionLink from "~/components/general/VersionLink.vue";
import { useRuntimeConfig } from "#app";

const config = useRuntimeConfig();
const version = config.public.VERSION;
</script>
<script>
export default {
  name: "PrivateFooter",
  emits: ["show-release-notes"],
  methods: {
    triggerReleaseNotesModal() {
      this.$emit("show-release-notes");
    },
  },
};
</script>
